import React from "react"

import CountryPage from "../components/country-page"
import withLocation from "../components/with-location"

type Props = {
  searchString: string
}

const PeruPage = ({ searchString }: Props) => (
  <CountryPage
    country="perú"
    date="05/02/2020"
    time="19:00 Hrs."
    flag="peru"
    office="WeWork Lima"
    address="Avenida Jorge Basadre 349"
    searchString={searchString}
  />
)

export default withLocation(PeruPage)
